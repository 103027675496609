import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Flex,
  useTheme,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  ListItem,
  List,
  Checkbox,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Input
} from '@chakra-ui/react';
import { useToast } from '@/web/common/hooks/useToast';
import { useSystemStore } from '@/web/common/system/useSystemStore';
import MyIcon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import ToolMenu from './ToolMenu';
import type {
  ChatHistoryItemResType,
  ChatItemType,
  ChatSiteItemType
} from '@fastgpt/global/core/chat/type';
import { useRouter } from 'next/router';
import { chatContentReplaceBlock } from '@fastgpt/global/core/chat/utils';
import HRFileSelect, { ComponentRef, FileItemType } from './HRFileSelect';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { getStandardDetail, addStandardRecord } from '@/web/core/chat/api';
import { StartChatFnProps, generatingMessageProps } from '@/components/ChatBox';
import { ModuleOutputKeyEnum } from '@fastgpt/global/core/module/constants';
import { adaptChat2GptMessages } from '@fastgpt/global/core/chat/adapt';

import { customAlphabet } from 'nanoid';
import { postQuestionGuide } from '@/web/core/ai/api';
import { postCreateDataset, postDatasetCollection } from '@/web/core/dataset/api';
import { hashStr } from '@fastgpt/global/common/string/tools';
import { chunksUpload, downloadFile } from '@/web/core/dataset/utils';
import { postCreateTrainingBill } from '@/web/support/wallet/bill/api';
import { useTranslation } from 'next-i18next';
import { te } from 'date-fns/locale';
import { exportCSV } from '@/web/common/file/utils';
import { useDatasetStore } from '@/web/core/dataset/store/dataset';
import { BucketNameEnum } from '@fastgpt/global/common/file/constants';
import dayjs from 'dayjs';
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz1234567890', 24);

const ChatCompare = ({
  history,
  appName,
  standardId,
  appAvatar,
  chatModels,
  appId,
  chatId,
  shareId,
  variables,
  showHistory,
  onOpenSlider,
  onStartChat
}: {
  history: ChatItemType[];
  appName: string;
  appAvatar: string;
  standardId: string;
  chatModels?: string[];
  appId?: string;
  chatId?: string;
  shareId?: string;
  showHistory?: boolean;
  variables?: { standardId: string; datasetId: string; fileId: string } | any;
  onOpenSlider: () => void;
  onStartChat: (e: StartChatFnProps) => Promise<{
    responseText: string;
    [ModuleOutputKeyEnum.responseData]: ChatHistoryItemResType[];
    isNewChat?: boolean;
    newChatId?: string;
    newTitle?: string;
  }>;
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const router = useRouter();
  const theme = useTheme();
  const { isPc } = useSystemStore();
  const [file, setFile] = useState<FileItemType>({} as FileItemType);
  const [status, setStatus] = useState<number>(0); //0:没有检查，1:检查中，2:检查完成
  const [current, setCurrent] = useState<number>(0); //当前分析完成的值
  const [infoList, setInfoList] = useState<any[]>([]);
  const [tagList, setTagList] = useState<string[]>([]); //标签列表

  const allChecked = infoList.every((v) => v.is_checked);
  const isIndeterminate = infoList.some((v) => v.is_checked) && !allChecked;
  const checkCount = infoList.filter((v) => v.is_checked).length;

  const [showUnCover, setShowUnCover] = useState<boolean>(false); //是否仅展示未覆盖
  const [loading, setLoading] = useState<boolean>(false); //是否检查
  const maxCount = infoList.length;
  const chatMsgList: ChatSiteItemType[] = []; //会话列表
  const FileSelectRef = useRef<ComponentRef>(null);

  const datasetId = useRef<string>(''); //知识库ID
  const tempChatId = useRef<string>(''); //知识库ID

  const title = useMemo(
    () =>
      chatContentReplaceBlock(history[history.length - 2]?.value)?.slice(0, 8) ||
      appName ||
      '新检查',
    [appName, history]
  );

  //拆分分析结果
  const extractText = (label: string, text: string) => {
    if (label == '相关段落') {
      let contentMatch = text.match(/相关段落：\s*([\s\S]*?)\s*相似程度：/);
      let tempTxt = contentMatch ? contentMatch[1].replaceAll('>  ', '') : '';
      if (!tempTxt) {
        contentMatch = text.match(/文档内容：\s*([\s\S]*?)\s*相似程度：/);
        tempTxt = contentMatch ? contentMatch[1].replaceAll('>  ', '') : '';
      }
      return tempTxt;
    } else if (label == '相似程度') {
      // 提取相似程度
      const similarityMatch = text.match(/相似程度：\s*(.*?)\s*\n/);
      let content = similarityMatch ? similarityMatch[1] : '';
      const valueArr = ['完全一致', '高度相似', '基本相似', '疑似', '内容缺失'];
      const chinessStrs = content.match(/[\u4e00-\u9fa5]+/g);
      let result = '';
      chinessStrs?.forEach((str) => {
        if (valueArr.includes(str)) {
          result = str;
        }
      });
      if (!result) {
        valueArr.forEach((value) => {
          if (content.indexOf(value) > -1) {
            result = value;
          }
        });
      }
      return result;
    } else if (label == '是否涵盖') {
      // 提取是否涵盖
      const coverageMatch = text.match(/是否涵盖：\s*(.*?)\s*\n/);
      let content = coverageMatch ? coverageMatch[1] : '';
      const chinessStrs = content.match(/[\u4e00-\u9fa5]+/g);
      const valueArr = ['是', '否'];
      let result = '';
      chinessStrs?.forEach((str) => {
        if (valueArr.includes(str)) {
          result = str;
        }
      });
      return result;
    } else if (label == '总结') {
      // 提取总结
      const summaryMatch = text.match(/总结：\s*([\s\S]*)/);
      return summaryMatch ? summaryMatch[1] : '';
    } else {
      return text;
    }
  };

  const getInfoList = useCallback(async () => {
    let clauseList = standardId ? await getStandardDetail({ id: standardId }) : [];
    if (history.length == 0) {
      //没有历史
      clauseList = clauseList.map((v) => {
        v.document = '待检查';
        v.result = '待检查';
        v.is_finish = false;
        v.is_checked = true;
        v.is_open = false;
        return v;
      });
      setInfoList(clauseList);
    } else {
      //有历史
      const tempList = [];
      for (let index = 0; index < history.length; index += 2) {
        const msg = history[index];
        const tmepInfo = history[index + 1];
        const clauseInfo = clauseList.find((v) => v.body == msg.value);
        tempList.push({
          title: clauseInfo?.title || '- -',
          body: msg.value,
          document: extractText('相关段落', tmepInfo.value),
          level: extractText('相似程度', tmepInfo.value),
          is_cover: extractText('是否涵盖', tmepInfo.value),
          result: extractText('总结', tmepInfo.value),
          is_checked: true,
          is_open: false,
          is_finish: true
        });
      }
      setInfoList(tempList);
    }
  }, [history, standardId]);

  useEffect(() => {
    if (tempChatId.current && tempChatId.current == chatId) return;
    if (status != 1) {
      if (standardId || history.length > 0) {
        file.fileId = variables.fileId || '';
        chatMsgList.length = 0;
        setCurrent(0);
        setShowUnCover(false);
        if (history.length > 0) {
          setStatus(2);
        } else {
          setStatus(0);
        }
      } else {
        setStatus(0);
        setInfoList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, standardId, chatId]);
  useEffect(() => {
    getInfoList();
  }, [getInfoList, standardId]);
  //单个切换选中状态
  const onChangeChecked = (e: any, index: number) => {
    // 阻止事件继续传播
    const newList = [...infoList];
    if (newList.length > index) {
      newList[index] = { ...newList[index], is_checked: e.target.checked };
    }
    setInfoList(newList);
  };
  const onChangeOpen = (info: any, index: number) => {
    const newList = [...infoList];
    if (newList.length > index) {
      newList[index] = { ...newList[index], is_open: !info.is_open };
    }
    setInfoList(newList);
  };
  //选中全部
  const onCheckAll = (e: any) => {
    const newList = [...infoList];
    newList.forEach((v) => {
      v.is_checked = e.target.checked;
    });
    setInfoList(newList);
    setTagList([]);
  };
  //开始检查
  const onBeginCheck = async () => {
    const tempList = infoList.filter((v) => v.is_checked);
    if (!file.fileId) {
      toast({
        status: 'warning',
        title: '请选择需要检查的文件'
      });
      return;
    }
    if (tempList.length == 0) {
      toast({
        status: 'warning',
        title: '请选择需要检查的项'
      });
      return;
    }
    setLoading(true);
    await sendMessage();
    setLoading(false);
  };
  const wait = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  //循环发送检查标准
  const sendMessage = async () => {
    setStatus(1);
    setCurrent(1);
    for (let index = 0; index < infoList.length; index++) {
      const tempInfo = infoList[index];
      if (tempInfo && tempInfo.is_checked) {
        const abortSignal = new AbortController();
        chatMsgList.push(
          {
            dataId: nanoid(),
            obj: 'Human',
            value: tempInfo.body,
            status: 'finish'
          },
          {
            dataId: nanoid(),
            obj: 'AI',
            value: '',
            status: 'loading'
          }
        );
        const messages = adaptChat2GptMessages({
          messages: chatMsgList,
          reserveId: true
        });
        console.log('发送消息：', tempInfo.body, chatMsgList.length);
        const dateTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const {
          responseData,
          responseText,
          isNewChat = false,
          newChatId,
          newTitle
        } = await onStartChat({
          chatList: chatMsgList.map((item) => ({
            dataId: item.dataId,
            obj: item.obj,
            value: item.value,
            status: item.status,
            moduleName: item.moduleName
          })),
          messages,
          controller: abortSignal,
          generatingMessage,
          variables: {
            dateTime,
            standardId,
            datasetId: datasetId.current,
            fileId: file.fileId,
            rawText: file.rawText
          }
        });
        tempChatId.current = newChatId || '';
        chatMsgList[chatMsgList.length - 1].status = 'finish';
        chatMsgList[chatMsgList.length - 1].value = responseText;

        const tempList = [...infoList];
        tempInfo.document = extractText('相关段落', responseText);
        tempInfo.level = extractText('相似程度', responseText);
        tempInfo.is_cover = extractText('是否涵盖', responseText);
        tempInfo.result = extractText('总结', responseText);
        tempInfo.is_finish = true;
        tempList[index] = tempInfo;
        setInfoList(tempList);
        await addStandardRecord({
          standard_id: standardId,
          clause_id: tempInfo.clause_id,
          title: tempInfo.title,
          body: tempInfo.body,
          document: tempInfo.document,
          result: tempInfo.result,
          level: tempInfo.level,
          is_cover: tempInfo.is_cover,
          chat_id: newChatId,
          chat_title: newTitle,
          file_id: file.fileId
        });
        setCurrent((state) => {
          return state + 1;
        });
      }
    }
    await createQuestionGuide({
      history: chatMsgList
    });
  };
  // create question guide
  const createQuestionGuide = useCallback(
    async ({ history }: { history: ChatSiteItemType[] }) => {
      try {
        const abortSignal = new AbortController();
        const result = await postQuestionGuide(
          {
            messages: adaptChat2GptMessages({ messages: history, reserveId: false }).slice(-6),
            shareId
          },
          abortSignal
        );
      } catch (error) {}
    },
    [shareId]
  );
  useEffect(() => {
    if (current != 0 && current > checkCount) {
      setStatus(2);
      setInfoList((state) => state.filter((v) => v.is_checked));
    }
  }, [current, checkCount]);

  //重新上传
  const onReUpload = async () => {
    setFile({} as FileItemType);
    FileSelectRef.current?.setFile({} as FileItemType);
  };

  //下载制度
  const onDownload = async () => {
    if (!file.fileId) {
      toast({
        status: 'warning',
        title: '暂无文件可下载'
      });
      return;
    }
    file.fileId && downloadFile(file.fileId);
  };

  //导出结果
  const onExport = async () => {
    const data = infoList
      .filter((v) => v.is_checked)
      .map((v) => {
        return {
          标题: v.title,
          标准原文: v.body,
          相关段落: v.document,
          相似程度: v.level,
          是否合规: v.is_cover,
          检查结果: v.result
        };
      });
    exportCSV(data);
  };

  const generatingMessage = ({ text = '', status, name }: generatingMessageProps) => {
    //接受到的消息
  };

  const addTag = (e: any) => {
    console.log(e, 'value===');
    tagList.push(e.target.value);
    e.target.value = '';
    setTagList([...tagList]);
    changeInfoList();
  };
  const removeTag = (index: number) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
    changeInfoList();
  };

  //更新数据
  const changeInfoList = () => {
    const newList = [...infoList];
    newList.forEach((v) => {
      v.is_checked = tagList.some((tag) => v.title.includes(tag));
    });
    setInfoList(newList);
  };

  return (
    <Flex height={'100%'} flexDirection={'column'} px={[3, 5]} py={[3, 5]} color={'myGray.900'}>
      {status == 0 && standardId ? (
        <Box mr={3} color={'myGray.1000'}>
          <Flex alignItems={'center'}>
            <HRFileSelect
              ref={FileSelectRef}
              fileExtension={'.docx'}
              onPushFiles={(files) => {
                datasetId.current = files[0].datasetId || '';
                setFile(files[0]);
              }}
              chunkLen={500} //分块
              overlapRatio={0.2}
              showUrlFetch={false}
              showCreateFile={false}
              tip={''}
              py={'10px'}
            />
            <Button colorScheme="blue" ml={'10px'} onClick={onBeginCheck} isLoading={loading}>
              开始检查
            </Button>
            {file.fileId && (
              <Button colorScheme="blue" variant="outline" ml={'10px'} onClick={onReUpload}>
                重新上传
              </Button>
            )}
          </Flex>
        </Box>
      ) : status == 1 ? (
        <Flex alignItems={'center'} justifyContent={'center'} width={'100%'}>
          <CircularProgress
            value={(current / checkCount) * 100}
            color="blue.600"
            size="70px"
            thickness="3px"
          >
            <CircularProgressLabel fontSize={'12px'}>
              {current}/{checkCount}
            </CircularProgressLabel>
          </CircularProgress>
          <Text marginLeft={'10px'} fontSize="13px">
            AI检查中，请稍后...
          </Text>
        </Flex>
      ) : status == 2 ? (
        <Flex mr={3} alignItems={'center'} justifyContent={'center'} width={'100%'}>
          <Box
            width={'60px'}
            textAlign={'center'}
            lineHeight={'55px'}
            height={'60px'}
            border={'1px'}
            borderColor={'green.500'}
            borderRadius={'30px'}
          >
            <Icon as={CheckIcon} w={8} h={8} color="green.500"></Icon>
          </Box>
          <Text marginLeft={'10px'} fontSize="13px">
            AI检查已完成！
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      {/* 列表 */}
      <Box flex={1} marginTop={'20px'}>
        <Flex alignItems={'center'} position={'relative'} marginBottom={'20px'} flexWrap={'wrap'}>
          {standardId && (
            <Text marginRight={'20px'} fontSize="14px" fontWeight={'bold'} flexShrink={0}>
              {status == 0 ? '检查标准' : '检查结果'}
            </Text>
          )}
          {status == 0 && standardId ? (
            <>
              <Checkbox
                flexShrink={0}
                colorScheme="blue"
                fontWeight={'bold'}
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={onCheckAll}
              >
                全选{checkCount}/{maxCount}
              </Checkbox>
              <HStack spacing={'10px'} marginLeft={'10px'} flexWrap={'wrap'}>
                {tagList.map((tag, ind) => (
                  <Tag key={tag} borderRadius="full" variant="solid" colorScheme="blue">
                    <TagLabel>{tag}</TagLabel>
                    <TagCloseButton onClick={() => removeTag(ind)} />
                  </Tag>
                ))}
                <Input
                  width={'100px'}
                  placeholder="输入标签"
                  variant="flushed"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      // 在此处编写回车事件的处理逻辑
                      addTag(e);
                    }
                  }}
                />
              </HStack>
            </>
          ) : standardId ? (
            <>
              <Checkbox
                flexShrink={0}
                colorScheme="blue"
                fontWeight={'bold'}
                isChecked={showUnCover}
                onChange={(e) => setShowUnCover(e.target.checked)}
              >
                仅显示不合规
              </Checkbox>
              <Box position={'absolute'} right={'10px'}>
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  p={'10px'}
                  ml={'10px'}
                  onClick={onDownload}
                >
                  下载制度文档
                </Button>
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  p={'10px'}
                  ml={'10px'}
                  onClick={onExport}
                >
                  导出结果
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Flex>
        <List spacing={3}>
          {infoList.map((info, i) =>
            showUnCover && info.is_cover == '是' ? (
              <ListItem key={i}></ListItem>
            ) : (
              <ListItem key={i} marginBottom={'20px'}>
                <Flex alignItems={'center'} cursor={'pointer'}>
                  {status != 2 ? (
                    <Checkbox
                      flexShrink={0}
                      width={'160px'}
                      colorScheme="blue"
                      fontWeight={'bold'}
                      isChecked={info.is_checked}
                      onChange={(e) => {
                        onChangeChecked(e, i);
                      }}
                    >
                      {info.title}
                    </Checkbox>
                  ) : (
                    <Text flexShrink={0} width={'160px'} colorScheme="blue" fontWeight={'bold'}>
                      {info.title}
                    </Text>
                  )}
                  <Text
                    className={'textEllipsis'}
                    flex={1}
                    marginLeft={'10px'}
                    fontSize="14px"
                    onClick={() => {
                      onChangeOpen(info, i);
                    }}
                  >
                    {info.body}
                  </Text>
                  {info.is_finish ? (
                    <>
                      <Text flexShrink={0} width={'130px'} marginLeft={'10px'} fontSize="14px">
                        相似程度：{info.level}
                      </Text>
                      <Box flexShrink={0} width={'90px'} marginLeft={'10px'} fontSize="14px">
                        是否合规：
                        <Text
                          display={'inline-block'}
                          color={info.is_cover == '是' ? 'green.600' : 'red.600'}
                        >
                          {info.is_cover}
                        </Text>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  <Icon
                    flexShrink={0}
                    width={'30px'}
                    as={info.is_open ? ChevronUpIcon : ChevronDownIcon}
                    w={7}
                    h={7}
                    color="myGray.300"
                    onClick={() => {
                      onChangeOpen(info, i);
                    }}
                  ></Icon>
                </Flex>
                {info.is_open ? (
                  <Box>
                    <Flex marginTop={'10px'}>
                      <Text flexShrink={0} width={'110px'} color={'myGray.500'}>
                        标准原文
                      </Text>
                      <Box as="pre" flex={1} color={'myGray.500'} whiteSpace={'pre-wrap'}>
                        {info.body}
                      </Box>
                    </Flex>
                    <Flex marginTop={'10px'}>
                      <Text flexShrink={0} width={'110px'} color={'myGray.500'}>
                        相关段落
                      </Text>
                      <Box as="pre" flex={1} color={'myGray.500'} whiteSpace={'pre-wrap'}>
                        {info.document}
                      </Box>
                    </Flex>
                    <Flex marginTop={'10px'}>
                      <Text flexShrink={0} width={'110px'} color={'myGray.500'}>
                        检查结果
                      </Text>
                      <Box as="pre" flex={1} color={'myGray.500'} whiteSpace={'pre-wrap'}>
                        {info.result}
                      </Box>
                    </Flex>
                  </Box>
                ) : (
                  <></>
                )}
              </ListItem>
            )
          )}
        </List>
      </Box>
    </Flex>
  );
};

export default ChatCompare;
